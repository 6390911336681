<template>
  <div class="contractDetails ContractHeight">
    <PageHeader :border="false" title="合约明细">
      <div v-if="IsEdit" class="coantractStatus">
        <el-switch
          v-if="enable===true || enable===false"
          v-model="enable"
          active-color="#39e839"
          active-text="已启用"
          inactive-text="已禁用"
          inactive-color="#f13535"
          @change="confirmupdateStatus"
        />
      </div>
    </PageHeader>
    <div v-loading.fullscreen.lock="fullscreenLoading" class="p-lr-20">
      <el-form ref="ContractInfo" :inline="true" :model="contractInfo" class="ContractConditions InfoBoxTwo6">
        <el-form-item label="买方：">
          {{ contractInfo.BuyCompanyName }}
        </el-form-item>
        <el-form-item label="供应商：">
          {{ contractInfo.SellCompanyName }}
        </el-form-item>
        <el-form-item v-if="contractInfo.ContractType === 0" label="终端：">
          {{ contractInfo.SendCompanyName }}
        </el-form-item>
        <el-form-item label="到期时间：">
          {{ contractInfo.DeadLine }}
        </el-form-item>
        <el-form-item label="当前状态：">
          <span v-if="contractInfo.Status === 1" class="contractStatus">{{ getInfo(contractInfo.Status) }}<i class="el-icon-success m-left-5" /></span>
          <span v-if="contractInfo.Status === 2" class="contractStatus">{{ getInfo(contractInfo.Status) }}<i class="el-icon-error m-left-5" /></span>
          <span v-if="contractInfo.Status === 3" class="contractStatus">{{ getInfo(contractInfo.Status) }}<i class="el-icon-remove m-left-5" /></span>
          <span v-if="contractInfo.Status === 4" class="contractStatus">{{ getInfo(contractInfo.Status) }}<i class="el-icon-error m-left-5" /></span>
        </el-form-item>
      </el-form>
      <div class="searchView j-between">
        <div v-if="IsEdit" style="float:left">
          <el-button v-if="contractInfo.ContractType === 0" round size="mini" @click="openSelectKS"><i class="el-icon-ship m-right-10" />科室</el-button>
          <el-button v-if="contractInfo.ContractType === 0" round size="mini" @click="oprenSelectContractUser(1)"><i class="el-icon-refresh m-right-10" />更换业务员</el-button>
        </div>
        <div style="float:right">
          <!-- <el-button round size="mini" ><i class="el-icon-document-copy m-right-10"/>销售授权书</el-button> -->
          <el-button round size="mini" @click.stop="() => $refs.FieldManagement.openSetFieldManagement()"><i class="el-icon-setting m-right-10" />字段管理</el-button>
          <el-button v-if="IsEdit" round size="mini" :disabled="isCanCopy" @click="opencopycontract"><i class="el-icon-document-copy m-right-10" />复制合约</el-button>
          <el-button round size="mini" @click="dealexportData"><i class="el-icon-document-copy m-right-10" />导出</el-button>
          <el-button v-if="IsEdit" round size="mini" @click="confirmDeleteMateial"><i class="el-icon-document-copy m-right-10" />删除</el-button>
          <el-button v-if="IsEdit" round size="mini" @click="openUpdatePrice"><i class="el-icon-document-copy m-right-10" />改价</el-button>
          <el-button v-if="IsEdit" round size="mini" @click="enableContractDetails(2)"><i class="el-icon-document-copy m-right-10" />下架</el-button>
          <el-button v-if="IsEdit" round size="mini" @click="enableContractDetails(1)"><i class="el-icon-document-copy m-right-10" />上架</el-button>
          <el-button v-if="!IsEdit" round size="mini" @click="openUpload"><i class="el-icon-document-copy m-right-10" />上传附件</el-button>
          <el-button v-if="!IsEdit" round size="mini" @click="EditContractDetails"><i class="el-icon-document-copy m-right-10" />编辑</el-button>
          <!-- <el-button round size="mini" v-if="!IsEdit" @click="DownloadFirstCampZip"><i class="el-icon-document-copy m-right-10"/>下载附件</el-button> -->
          <el-button v-if="!IsEdit" round size="mini" @click="openAddMateiral"><i class="el-icon-document-copy m-right-10" />添加产品</el-button>
          <el-button v-if="!IsEdit" round size="mini" type="primary" style="background:rgb(58, 120, 241)" @click="comfirmaddNewMaterial"><i class="el-icon-document-copy m-right-10" />保存新增物料</el-button>
          <el-button v-if="IsEdit" round size="mini" type="primary" style="background:rgb(58, 120, 241)" @click="comfirmMaterialSave"><i class="el-icon-document-copy m-right-10" />保存</el-button>
          <el-button v-if="!IsEdit" round size="mini" @click="() => $router.push({name: 'salesContract2', meta: { title: contractInfo.ContractType === 0 ? '销售合约': '采购合约', icon: 'el-icon-s-platform', contractType: contractInfo.ContractType === 0 ? 2:1 } })"><i class="el-icon-document-copy m-right-10" />返回</el-button>
        </div>
      </div>
      <div class="materilaiData">
        <el-table
          ref="materiallist"
          v-loading="MaterialListDataloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="contractInfo.Materials"
          tooltip-effect="dark"
          show-header
          border
          max-height="500"
          style
          @selection-change="checkedAddMaterialhandlechange"
        >
          <af-table-column
            fixed="left"
            type="selection"
            width="40"
          />
          <template v-for="(item,index) in contractDetails">
            <el-table-column
              v-if="item.isChecked && item.prop !== 'StatusString' && item.prop !== 'CustomInvCode' && item.prop !== 'TaxRate' && item.prop !== 'invtypename' && item.prop !== 'PriceFormat' && item.prop !== 'SalesName'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              width="110"
            />
            <el-table-column
              v-if="item.isChecked && item.prop === 'invtypename'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 类型 -->
              <template v-if="item.isChecked && item.prop === 'invtypename'" slot-scope="scope">
                {{ scope.row.MaterialTypeName }} - {{ scope.row.MaterialSubTypeName }}
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'SalesName' && contractInfo.ContractType === 0"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              width="120"
            >
              <!-- 业务员 -->
              <template v-if="item.isChecked && item.prop === 'SalesName'" slot-scope="scope">
                <el-link v-if="(scope.row.UserName === null || scope.row.UserName.length < 1)? true : false" :disabled="scope.row.IsEditDetails" type="primary" @click="oprenSelectContractUser((scope.row.IDX > 0)?3:4, scope.row)">选择业务员</el-link>
                <el-link v-if="(scope.row.UserName !== null && scope.row.UserName.length >0)? true : false" :disabled="scope.row.IsEditDetails" type="primary" @click="oprenSelectContractUser((scope.row.IDX > 0)?3:4, scope.row)">{{ scope.row.SalesName }}</el-link>
              </template>
            </el-table-column>
            <!-- && contractInfo.ContractType === 0 -->
            <el-table-column
              v-if="item.isChecked && item.prop === 'PriceFormat'"
              :key="index"
              width="100px"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 合约含税价格 -->
              <template v-if="item.isChecked && item.prop === 'PriceFormat'" slot-scope="scope">
                <el-input v-model="scope.row.PriceFormat" :disabled="scope.row.IsEditDetails" />
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'TaxRate'"
              :key="index"
              width="100px"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 税率 -->
              <template v-if="item.isChecked && item.prop === 'TaxRate'" slot-scope="scope">
                <el-select v-model="scope.row.TaxRate" :disabled="scope.row.IsEditDetails" placeholder="">
                  <el-option label="17%" :value="0.17" />
                  <el-option label="16%" :value="0.16" />
                  <el-option label="13%" :value="0.13" />
                  <el-option label="11%" :value="0.11" />
                  <el-option label="10%" :value="0.1" />
                  <el-option label="6%" :value="0.06" />
                  <el-option label="3%" :value="0.03" />
                  <el-option label="1%" :value="0.01" />
                  <el-option label="0" :value="1" />
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              v-if="item.isChecked && item.prop === 'CustomInvCode'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
              width="100"
            >
              <!-- 对方物料编号 -->
              <template v-if="item.isChecked && item.prop === 'CustomInvCode'" slot-scope="scope">
                <el-input v-model="scope.row.CustomInvCode" :disabled="scope.row.IsEditDetails" />
              </template>
            </el-table-column>
            <!-- && contractInfo.ContractType === 0 -->
            <el-table-column
              v-if="item.isChecked && item.prop === 'StatusString'"
              :key="index"
              :label="item.label"
              :prop="item.prop"
              show-overflow-tooltip
            >
              <!-- 状态 -->
              <template v-if="item.isChecked && item.prop === 'StatusString'" slot-scope="scope">
                <div v-if="scope.row.RefMaterialStatus === 2">
                  <span class="statusColor2">物料已禁用</span>
                </div>
                <div v-else>
                  <span v-if="scope.row.Status === 1" class="statusColor1">{{ scope.row.StatusString }}</span>
                  <span v-else-if="scope.row.Status === 2 || scope.row.Status === 3" class="statusColor2">{{ scope.row.StatusString }}</span>
                  <span v-else>{{ scope.row.StatusString }}</span>
                </div>
              </template>
            </el-table-column>
          </template>
          <el-table-column
            prop="StatusString"
            label="操作"
            fixed="right"
            width="50"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.IDX < 1" class="cursor" style="color:#409EFF" @click="removeAddedMaterial(scope.$index)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      <div class="pagination-container">
        <el-pagination
          :total="DetailsTotalCount"
          :current-page="DetailsPageIndex"
          :page-size="DetailsPageSize"
          :background="true"
          layout="prev, pager, next, jumper"
          @current-change="detailshandleCurrentChange"
        />
      </div>
      </div>
    </div>
    <!-- 科室 -->
    <el-dialog class="DepartmentBox" title="科室" :visible.sync="departmentVisibel" width="800px" :close-on-click-modal="false">
      <div class="ksclass" style="text-align: center;width:100%">
        <el-transfer
          v-model="checkedks"
          style="text-align:left; display: inline-block; width:100%"
          filterable
          :titles="['科室列表', '已选科室']"
          :button-texts="['', '']"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }"
          :data="ksDataList"
          @change="kshandleChange"
        >
          <span slot-scope="{ option }">{{ option.key }} - {{ option.label }}</span>
        </el-transfer>
      </div>
      <div class="caozuo p-tb-20">
        <el-button @click="departmentVisibel = false">取 消</el-button>
        <el-button type="primary" @click="confirmCheckedKS">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 新增产品 -->
    <el-dialog title="添加产品" :visible.sync="addMaterialVisbel" width="800px" :close-on-click-modal="false">
      <div class="p-lr-20">
        <div class="searchView  flex a-center j-between SearchBox_30 m-bottom-20">
          <div class="flex" style="flex:1">
            <el-input v-model="listaddmaterialParas.keyWords" size="medium  " placeholder="请输入关键字" class="rule-input-edit">
              <template slot="prepend">
                <div class="cursor" @click="openamterialaddvance">
                  <i class="el-icon-s-operation" />高级搜索</div></template>
              <div slot="append" @click="getAddMaterialMaterlaiList()">搜  索</div>
            </el-input>
            <div class="out p-left-10  flex a-center">
              <el-button round size="mini" @click="addMaterial"><i class="el-icon-circle-plus-outline" />添加选中</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-lr-20">
        <el-table
          v-loading="addMaterialloading"
          :header-cell-style="{background:'rgb(247,248,252)'}"
          :data="addMaterialList"
          tooltip-effect="dark"
          show-header
          :max-height="$minCommon.SetTableHeight(330)"
          border
          @selection-change="addmaterhandleSelectionChange"
        >
          <af-table-column
            fixed="left"
            type="selection"
            :selectable="addmaterilacheckboxInit"
            width="40"
          />
          <af-table-column
            prop="InvCode"
            label="物料编号"
          />
          <af-table-column
            prop="InvName"
            label="物料名称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="NickName"
            label="物料简称"
            show-overflow-tooltip
          />
          <af-table-column
            prop="BrandName"
            label="品牌"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MaterialTypeName"
            label="物料类型"
            show-overflow-tooltip
          />
          <af-table-column
            prop="Specification"
            label="规格/型号"
            show-overflow-tooltip
          />
          <af-table-column
            prop="MRCStockUnitN"
            label="单位"
            show-overflow-tooltip
          />
          <af-table-column
            prop=""
            label="操作"
            show-overflow-tooltip
            width="50"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-link v-if="!scope.row.ischecked" :underline="false" @click="addoneMaterial(scope.row)">添加</el-link>
            </template>
          </af-table-column>
        </el-table>

        <div class="pagination-container">
          <el-pagination
            :total="addMateriallistSize"
            :current-page="listaddmaterialParas.PageIndex"
            :page-size="listaddmaterialParas.PageSize"
            :background="true"
            layout="prev, pager, next, jumper"
            @current-change="addMateriallistCurrentChange"
          />
        </div>
      </div>
      <!-- <el-divider></el-divider>
            <div style="text-align: center;">
                <el-button @click="addMaterialVisbel = false">取 消</el-button>
                <el-button type="primary" @click="confirmCheckedKS">确 定</el-button>
            </div> -->
    </el-dialog>
    <!-- 物料高级搜索 -->
    <el-dialog class="DepartmentBox" title="物料高级搜索" :visible.sync="AdvancedsearchmaterialVisibel" width="800px" :close-on-click-modal="false" @closed="closedmaterialadvance">
      <el-button style="float:right" size="mini" plain @click="initaddmatereialserch">清空搜索条件</el-button>
      <el-form label-position="right" label-width="100px" :inline="true" :model="listaddmaterialParas" size="mini" class="advanser InfoBoxTwo3">
        <el-form-item label="时间区间">
          <el-date-picker
            v-model="listaddmaterialParas.CreateStartTime"
            type="date"
            style="width:calc((100% - 15px) / 2)"
            value-format="yyyy-MM-dd"
          />
          -
          <el-date-picker
            v-model="listaddmaterialParas.CreateEndTime"
            type="date"
            style="width:calc((100% - 15px) / 2)"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="物料编号">
          <el-input
            v-model="listaddmaterialParas.InvCode"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="品牌">
          <el-input
            v-model="listaddmaterialParas.BrandName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料名称">
          <el-input
            v-model="listaddmaterialParas.InvName"
            size="mini"
            placeholder=""
            width="150"
          />
        </el-form-item>
        <el-form-item label="物料类型">
          <el-input
            v-model="listaddmaterialParas.MaterialTypeName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="物料简称">
          <el-input
            v-model="listaddmaterialParas.NickName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="注册证号">
          <el-input
            v-model="listaddmaterialParas.CertificateName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="UDI码">
          <el-input
            v-model="listaddmaterialParas.DINum"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="原厂编号">
          <el-input
            v-model="listaddmaterialParas.ManuPartNum"
            size="mini"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="生产厂家">
          <el-input
            v-model="listaddmaterialParas.ManfacturerName"
            size="mini"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <div class="caozuo t-right p-right-20 t_line_s p-tb-20">
        <el-button @click="AdvancedsearchmaterialVisibel = false">取 消</el-button>
        <el-button type="primary" @click="confirmSercherMaterial">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 改价 -->
    <el-dialog class="DepartmentBox" title="改价" :visible.sync="updatepriceVisibel" width="400px" :close-on-click-modal="false">
      <el-form label-position="right" label-width="100px" :model="updatepriceInfo" size="mini" class="demo-form-inline">
        <el-form-item label="改价方式" class=" is-required ">
          <el-radio v-model="updatepriceInfo.EditType" :label="1">按比例</el-radio>
          <el-radio v-model="updatepriceInfo.EditType" :label="2">按固定价</el-radio>
        </el-form-item>
        <el-form-item v-if="updatepriceInfo.EditType === 1" label="设置比例" class=" is-required" prop="precent">
          <div class="flex">
            <el-input-number v-model="updatepriceInfo.precent" :min="0" :max="999999" />%
          </div>
        </el-form-item>
        <el-form-item v-if="updatepriceInfo.EditType === 2" label="合约价格" class=" is-required " prop="price">
          <el-input-number v-model="updatepriceInfo.price" :min="0" :max="99999999" />
        </el-form-item>
      </el-form>
      <el-divider />
      <div class="caozuo t-right">
        <el-button @click="updatepriceVisibel = false">取 消</el-button>
        <el-button type="primary" @click="comfirmUpatePrice">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 上传附件 -->
    <el-dialog class="FJUpDate" :visible.sync="uploadVisibel" width="440px" :close-on-click-modal="false">
      <div class="uploadFiles">
        <el-upload
          ref="upload"
          :multiple="false"
          class="upload-demo"
          action=""
          :on-change="getFile"
          :auto-upload="false"
        >
          <el-button slot="trigger" class="UpDatebutton"><i class="el-icon-document-add" /><br>选取文件</el-button>
          <el-button class="UpDatebutton" @click="submitUpload"><i class="el-icon-upload2" /><br>上传到服务器</el-button>
          <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
        </el-upload>
      </div>
    </el-dialog>
    <!-- 复制合约 -->
    <el-dialog class="DepartmentBox" title="复制合约" :visible.sync="copycontractVisibel" width="500px" :close-on-click-modal="false">
      <el-form ref="createContract" :rules="copyContractrules" label-position="right" label-width="80px" :model="contractInfoCopy" size="medium" class="createContractDialog">
        <el-form-item label="合约名称" style="width:100%" prop="Name">
          <el-input
            v-model="contractInfoCopy.Name"
            placeholder=""
          />
        </el-form-item>
        <el-form-item label="买方" style="width:100%" prop="BuyCompanyID">
          <!-- <el-select v-model="contractInfoCopy.BuyCompanyID" placeholder="请选择甲方名称" filterable style="width:100%" :disabled="isEditBuy">
              <el-option  v-for="(item, index) in BuyCompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfoCopy.BuyCompanyID"
            style="width:100%"
            :disabled="isEditBuy"
            filterable
            remote
            reserve-keyword
            placeholder="请选择甲方名称"
            :remote-method="buyremoteMethod"
            :loading="selectBuyloading"
          >
            <el-option
              v-for="(item, index) in BuyCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="供应商" style="width:100%" prop="SellCompanyID">
          <!-- <el-select v-model="contractInfoCopy.SellCompanyID" placeholder="" filterable style="width:100%" :disabled="isEditSell">
              <el-option  v-for="(item, index) in SellcompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfoCopy.SellCompanyID"
            style="width:100%"
            :disabled="isEditSell"
            filterable
            remote
            reserve-keyword
            placeholder=""
            :remote-method="sellremoteMethod"
            :loading="selectsellloading"
          >
            <el-option
              v-for="(item, index) in SellcompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="contractInfo.ContractType === 0" label="终端" style="width:100%" prop="SendCompanyID">
          <!-- <el-select v-model="contractInfoCopy.SendCompanyID" placeholder="请选择送达方（终端客户）" filterable style="width:100%">
              <el-option  v-for="(item, index) in SendCompanyList"  :key="index" :label="item.text" :value="item.id" />
            </el-select> -->
          <el-select
            v-model="contractInfo.SendCompanyID"
            style="width:100%"
            filterable
            remote
            reserve-keyword
            placeholder=""
            :remote-method="sendremoteMethod"
            :loading="selectsendloading"
          >
            <el-option
              v-for="(item, index) in SendCompanyList"
              :key="index"
              :label="item.text"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="失效时间" style="width:100%" prop="DeadLine">
          <el-date-picker
            v-model="contractInfoCopy.DeadLine"
            type="date"
            placeholder="请选择失效时间"
            value-format="yyyy-MM-dd"
            style="width:100%"
          />
        </el-form-item>
        <el-form-item v-if="contractInfo.ContractType === 0" label="业务员" style="width:100%">
          <el-input v-model="contractInfoCopy.NickName" placeholder="请选择业务员" class="input-with-select" :readonly="true">
            <el-button slot="append" icon="el-icon-search" @click="oprenSelectContractUser(2)" />
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="caozuo">
        <el-button @click="copycontractVisibel = false">关 闭</el-button>
        <el-button type="primary" @click="confirmCopyContract">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 选择业务员 -->
    <el-dialog class="DepartmentBox" title="批量替换业务员" center :visible.sync="seletUserVisible" width="400px" :close-on-click-modal="false">
      <div class="rolebody">
        <el-tabs v-model="selectUseractiveName">
          <el-tab-pane label="成员" name="user" />
          <el-tab-pane v-if="isshow" label="部门" name="dept" />
          <el-tab-pane v-if="isshow" label="角色" name="role" />
        </el-tabs>
        <div v-if="selectUseractiveName === 'user'" class="selectuser">
          <el-input
            v-model="selectUserkeywords"
            size="small"
            placeholder="搜索成员"
            prefix-icon="el-icon-search"
          />
          <div class="selectUserTree">
            <el-tree
              ref="allusertree"
              :data="connuserlist"
              node-key="value"
              highlight-current
              :filter-node-method="alluserfilterNode"
            >
              <span slot-scope="{ node, data }" style="width:100%">
                <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
                <span class="deptnamespan">
                  {{ node.label }}</span>
                <!-- nodeType- 0：部门 1：员工 -->
                <span v-if="data.nodeType === 1" class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkeduser(data.ischecked,data.value,data)" /></span>
              </span>
            </el-tree>
          </div>
        </div>
        <div v-if="selectUseractiveName === 'dept'" class="selectuser">
          <el-tree
            ref="tree"
            :data="selectUserDeptData"
            node-key="value"
            highlight-current
          >
            <span slot-scope="{ node, data }" style="width:100%">
              <span class="roleSpan"><div class="deptSpandiv"><i class="el-icon-user" /></div></span>
              <span class="deptnamespan">
                {{ node.label }}</span>
              <span class="deptcountspan"><el-checkbox v-model="data.ischecked" @change="checkedDept(data.ischecked,data.value,data)" /></span>
            </span>
          </el-tree>
        </div>
        <div v-if="selectUseractiveName === 'role'" class="selectuser">
          <div v-for="(item, index) in selectUserroleData" :key="index" class="selectuserrole">
            <span class="deptnamespan">
              {{ item.name }}</span>
            <span class="deptcountspan"><el-checkbox v-model="item.ischecked" /></span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="seletUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="doconnuser">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" action-name="GetContactDetailsQueryList" :list="contractDetails" name="contractDetails" :change-list-table="changeListTable" />
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
  </div>
</template>
<script>
import contractDetails from '@/minxin/contract/contractDetails.js'
export default contractDetails
</script>
<style scoped lang='scss'>
@import '@/style/search.scss';
@import '@/style/index.scss';
@import '@/style/main.scss';
@import '@/style/salesContract.scss';
</style>
