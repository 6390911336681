import PageHeader from '@/components/PageHeader.vue'
import $contractreq from '@/request/contractApi.js'
import $userRequest from '@/request/contactsapi.js'
// import { NULL } from 'node-sass'
const contractDetails = {
  name: 'contractDetails',
  components: {
    PageHeader
  },
  data() {
    return {
      contractDetails: [
        {
          'prop': 'InvCode',
          'label': '物料编号',
          'isChecked': true
        },
        {
          'prop': 'ManuPartNum',
          'label': '原厂编号',
          'isChecked': true
        },
        {
          'prop': 'MaterialName',
          'label': '物料名称',
          'isChecked': true
        },
        {
          'prop': 'MaterialSpefication',
          'label': '规格',
          'isChecked': true
        },
        {
          'prop': 'ImportUnit',
          'label': '单位',
          'isChecked': true
        },
        {
          'prop': 'invtypename',
          'label': '类型',
          'isChecked': true
        },
        {
          'prop': 'MaterialBrandName',
          'label': '品牌',
          'isChecked': true
        },
        {
          'prop': 'SalesName',
          'label': '业务员',
          'isChecked': true
        },
        {
          'prop': 'PriceFormat',
          'label': '合约含税价格',
          'isChecked': true
        },
        {
          'prop': 'TaxRate',
          'label': '税率',
          'isChecked': true
        },
        {
          'prop': 'CustomInvCode',
          'label': '对方物料编号',
          'isChecked': true
        },
        {
          'prop': 'StatusString',
          'label': '状态',
          'isChecked': true
        }
      ],
      fullscreenLoading: false,
      contractInfo: {
        IDX: 2,
        ContactNo: '', // 合约编号
        BuyCompanyID: null,
        BuyCompanyName: '',
        SellCompanyID: null,
        SellCompanyName: '',
        SendCompanyID: null,
        SendCompanyName: '',
        DeadLine: '',
        StatusName: '',
        ContractType: null, // 合约类型 0-销售合约；1-采购合约
        Status: 1,
        Materials: [
        //   {
        //   IDX: 1,
        //   InvCode: '1123123',
        //   ManuPartNum: 'dsfsa', // 原厂编号
        //   MaterialTypeName: '',
        //   MaterialSubTypeName: '',
        //   MaterialSpefication: 'fdsaffdsf', // 规格
        //   ImportUnit: '盒、box', // 单位
        //   MaterialName: 'fdsa', // 物料名称
        //   MaterialBrandName: 'fdsa', // 品牌
        //   UserName: 'fsafs', // UserName 业务员
        //   PriceFormat: null, // 含税价格
        //   TaxRate: null, // 税率
        //   CustomInvCode: 'fdsfs', // 映射码
        //   Status: 1,
        //   StatusString: '', // 物料状态
        //   IsEditDetails: true, // 是否可编辑 true 不可 false 可
        //   ischecked: false // 是否已经被这张合约选中
        // }
        ]
      },
      enable: null,
      contractInfoCopy: {
      },
      // 物料列表
      MaterialListDataloading: false,
      IsEdit: false, // 是否是编辑状态
      // 选择科室
      departmentVisibel: false,
      checkedks: [], // 选中的科室
      ksDataList: [
        {
          key: 1,
          label: '采购部'
        },
        {
          key: 2,
          label: '订单组'
        },
        {
          key: 3,
          label: '驻场'
        },
        {
          key: 4,
          label: '总可恶'
        },
        {
          key: 5,
          label: '胜浦组'
        },
        {
          key: 6,
          label: '胜浦组'
        }
      ], // 科室列表
      leftdefaultchecked: [], // 科室左侧选中
      rightdefaultchecked: [], // 科室右侧选中
      // 物料明细添加物料
      addMaterialVisbel: false,
      addMaterialloading: false,
      addMaterialList: [
        // {
        //   IDX: 1,
        //   InvCode: '1123123',
        //   ManuPartNum: 'dsfsa',
        //   InvName: '范德萨发散的防守打法撒发送',
        //   Specification: 'fdsaffdsf',
        //   MRCStockUnitN: '盒、box',
        //   MaterialTypeName: 'fdsa',
        //   BrandName: 'fdsa',
        //   operater: 'fsafs',
        //   hsjg: null,
        //   sl: null,
        //   ysm: 'fdsfs',
        //   materialstatus: 1
        // }
      ],
      checkedAddMaterial: [], // 选中的添加物料数据
      // 物料高级搜索
      listaddmaterialParas: {
        IsAdvancedSearch: 0, // --是否高级搜索  0否 1是
        keyWords: '',
        SellCompanyID: null,
        InvCode: '', // 物料编号
        CertificateDeadLineBeginDate: '',
        CertificateDeadLineEndDate: '',
        BrandName: '', // 品牌
        InvName: '', // 物料名称
        MaterialTypeName: '', // 物料类型  --
        NickName: '', // 物料简称  --
        CertificateName: '', // 注册证号
        DINum: '', // udi码
        ManuPartNum: '', // 原厂编号
        ManfacturerName: '', // 生产厂家
        PageIndex: 1,
        PageSize: 10
      }, // 物料搜索参数
      AdvancedsearchmaterialVisibel: false,
      addMateriallistSize: null,
      // 改价
      updatepriceVisibel: false,
      updatepriceInfo: {
        ContactID: 35743, // 合约id
        IdxList: '',
        EditType: 1, // 1: 按比例 2：固定
        Value: null,
        precent: 30,
        price: null
      }, // 改价
      checkedMaterial: [], // 选中的物料明细数据
      _contractID: null,
      exportParas: {
        ContractID: null,
        ContractDetailsIDList: null
      }, // 导出明细参数
      // 上传附件
      uploadVisibel: false,
      fileList: [],
      fileBase64: null,
      filetype: null,
      filename: null,
      // 复制合约
      userInfo: null,
      isCanCopy: true,
      copycontractVisibel: false,
      isEditSell: true,
      isEditBuy: true,
      // 供应商list
      SellcompanyList: [
      ],
      // 买方list
      BuyCompanyList: [],
      // 终端list
      SendCompanyList: [
      ],
      // 获取公司列表参数
      sellCompanyListParas: {
        CompanyType: null, //  0、客户；1、供应商
        CompanyName: '', // 关键字
        pageIndex: 1,
        pageSize: -1
      },
      copyContractrules: {
        ContractName: [{ required: true, message: '请填写合约名称', trigger: 'blur' }],
        BuyCompanyID: [
          { required: true, message: '请选择买方', trigger: 'blur' },
          { required: true, message: '请选择买方', trigger: 'change' }
        ],
        SellCompanyID: [
          { required: true, message: '请选择供应商', trigger: 'blur' },
          { required: true, message: '请选择供应商', trigger: 'change' }
        ],
        SendCompanyID: [
          { required: true, message: '请选择终端', trigger: 'blur' },
          { required: true, message: '请选择终端', trigger: 'change' }
        ],
        DeadLine: [
          { required: true, message: '请选择失效时间', trigger: 'blur' },
          { required: true, message: '请选择失效时间', trigger: 'change' }
        ]
      },
      IsCopyContract: false, // 是否复制合约
      oldContractID: null,
      selUserType: null,
      // ================================================= 选择人员 begin  =================================================
      // ================================================= 选择人员 begin ==================================================
      seletUserVisible: false,
      selectUseractiveName: 'user', // 选择人员tab切换
      isshow: false, // 控制 部门和角色选择是否显示
      selectUserkeywords: '', // 搜索成员关键字
      connuserlist: [], // 成员list
      selectUserDeptData: [], // 部门数据
      selectUserroleData: [], // 角色数据
      checkedUserInfo: null, // 选中的人员对象
      checkedContractOptionUserID: null, // 选中人员的id
      checkedContractOptionUserName: null, // 选中人员的name
      checkedContractOptionUserNickName: null, // 选中人员的NickName
      // ================================================= 选择人员 end  =================================================
      // ================================================= 选择人员 end ==================================================
      checkedMaterialitem: [], // 选中的物料对象
      contractDetailItemByOperate: null,
      contactType: null, // 合约类型
      // 物料高级搜索选择供应商
      // 选择甲方
      selectBuyloading: false,
      // 选择供应商
      selectsellloading: false,
      // 选择终端
      selectsendloading: false,
      allbuyCompanyList: [],
      allSaleCompanyList: [],
      allCustomerCompanyList: [],
      detailsListParas: {
        PageIndex: 1,
        PageSize: 10,
        ContactID: -1
      },
      exportParams: {
        ContractID: null,
        ContractDetailsIDList: null,
        ContractType: ''
      },
      DetailsTotalCount: 0, // 明细总行数
      DetailsPageIndex: 1, // 明细当前页
      DetailsPageSize: 50 // 明细每页行数
    }
  },
  watch: {
    selectUserkeywords(val) {
      this.$refs.allusertree.filter(val)
    }
  },
  mounted() {
    console.log(this.$route.params)
    this._contractID = this.$route.params.idx
    this.exportParams.ContractID = this.$route.params.idx
    this.exportParams.ContractType = this.$route.params.type
    this.getUserInfo()
    this.getContractDetailByid(this._contractID)
    this.getSellCompanyList()
    this.getBuyCompanyList()

    const dom = this.$refs.materiallist.bodyWrapper
    dom.addEventListener('scroll', function() {
      const scrollDistance = dom.scrollHeight - dom.scrollTop - dom.clientHeight
      if (scrollDistance <= 0) {
        // pageIndex 当前页 totalPage  总页数
        if (this.pageIndex < this.totalPage) {
          this.pageIndex++// 当前页数自增
          // 请求接口 获取最新数据
        }
      }
      console.log(scrollDistance)
    })
  },
  methods: {
    // 改变当前页
    detailshandleCurrentChange(val) {
      this.DetailsPageIndex = val
      this.GetContractDetailsByID()
    },
    changeListTable(e, name, ActionName, type) {
      this.contractDetails = e
      const type_ = type === 0 ? '' : type
      this.$api.SetUserField({ ActionName: ActionName + type_, FieldStr: JSON.stringify(this.contractDetails) }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('字段管理设置错误：' + res.RetMsg)
      }).catch(err => {
        return this.$message.error('字段管理设置接口错误：' + err)
      })
    },
    // 启用禁用
    confirmupdateStatus(val) {
      const _this = this
      let message = '确认启用合约吗？'
      if (!val) {
        message = '确认禁用合约吗？'
      }
      _this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.updateStatus()
      }).catch(() => {
      })
    },
    updateStatus() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.UpdateContractStatus({ IDX: _this._contractID }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          this.getContractDetailByid(this._contractID)
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 初始化物料高级查询条阿金
    initaddmatereialserch() {
      this.listaddmaterialParas = {
        IsAdvancedSearch: 0, // --是否高级搜索  0否 1是
        keyWords: '',
        SellCompanyID: null,
        InvCode: '', // 物料编号
        CertificateDeadLineBeginDate: '',
        CertificateDeadLineEndDate: '',
        BrandName: '', // 品牌
        InvName: '', // 物料名称
        MaterialTypeName: '', // 物料类型  --
        NickName: '', // 物料简称  --
        CertificateName: '', // 注册证号
        DINum: '', // udi码
        ManuPartNum: '', // 原厂编号
        ManfacturerName: '', // 生产厂家
        PageIndex: 1,
        PageSize: 10
      }
    },
    // 终端选择
    sendremoteMethod(query) {
      this.selectsendloading = true
      this.SendCompanyList = []
      if (query && query.length > 0) {
        this.allCustomerCompanyList.forEach((item) => {
          if (this.SendCompanyList && this.SendCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.SendCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allCustomerCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.SendCompanyList.push(this.allCustomerCompanyList[i])
          }
        } else {
          this.SendCompanyList = this.allCustomerCompanyList
        }
      }
      this.selectsendloading = false
    },
    // 供应商选择
    sellremoteMethod(query) {
      this.selectsellloading = true
      this.SellcompanyList = []
      if (query && query.length > 0) {
        this.allSaleCompanyList.forEach((item) => {
          if (this.SellcompanyList && this.SellcompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.SellcompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allSaleCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.SellcompanyList.push(this.allSaleCompanyList[i])
          }
        } else {
          this.SellcompanyList = this.allSaleCompanyList
        }
      }
      this.selectsellloading = false
    },
    // 甲方选择
    buyremoteMethod(query) {
      this.selectBuyloading = true
      this.BuyCompanyList = []
      if (query && query.length > 0) {
        this.allbuyCompanyList.forEach((item) => {
          if (this.BuyCompanyList && this.BuyCompanyList.length < 21) {
            if (item.text) {
              if (item.text.indexOf(query) > -1) {
                this.BuyCompanyList.push(item)
              }
            }
          }
        })
      } else {
        if (this.allbuyCompanyList.length > 5) {
          for (let i = 0; i < 5; i++) {
            this.BuyCompanyList.push(this.allbuyCompanyList[i])
          }
        } else {
          this.BuyCompanyList = this.allbuyCompanyList
        }
      }
      this.selectBuyloading = false
    },
    // 添加物料框置灰操作
    addmaterilacheckboxInit(row, index) {
      if (row.ischecked || (row.MaterialStatus && row.MaterialStatus.length > 0)) {
        return 0 // 不可勾选
      } else {
        return 1 // 可勾选
      }
      // this.contractInfo.Materials.forEach((ele) => {
      //   if (ele.MaterialID === row.MaterialID) {
      //     return 0// 不可勾选
      //   } else {
      //     return 1// 可勾选
      //   }
      // })
    },
    // 删除新增明细
    removeAddedMaterial(_index) {
      this.contractInfo.Materials.splice(_index, 1)
    },
    // 合约明细打开编辑
    EditContractDetails() {
      const _this = this
      // 判断是否有新增的物料未保存
      let isgoing = true
      _this.contractInfo.Materials.forEach((ele) => {
        if (!ele.IDX || ele.IDX < 1) {
          isgoing = false
          return false
        }
      })
      if (!isgoing) {
        _this.$confirm('还有未保存的新增物料数据，如果进入编辑状态则会删除未保存的新增数据，确认直接进入编辑状态吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          for (let i = 0; i < _this.contractInfo.Materials.length; i++) {
            if (!_this.contractInfo.Materials[i].IDX || _this.contractInfo.Materials[i].IDX < 1) {
              _this.contractInfo.Materials.splice(i, 1)
              i--
            }
          }
          _this.contractInfo.Materials.forEach((ele, index) => {
            ele.IsEditDetails = false
          })
          _this.IsEdit = !_this.IsEdit
        }).catch(() => {
        })
      } else {
        _this.contractInfo.Materials.forEach((ele, index) => {
          ele.IsEditDetails = false
        })
        _this.IsEdit = !_this.IsEdit
      }
    },
    // 合约zip打包
    DownloadFirstCampZip() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.DownloadFirstCampZip({ JsonStr: _this.contractInfo.FileJsonStr }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.downloadFile(res.Message, _this.guid() + '.zip')
        } else {
          _this.$message(res.RetMsg)
        }
      })
    },
    // 验证合约物料删除
    confirmDeleteMateial() {
      const _this = this
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        _this.$message('请至少选择一条数据')
        return false
      }
      _this.$confirm('确认删除选中的数据吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.DeleteMateial()
      }).catch(() => {
      })
    },
    DeleteMateial() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.DeleteContractDetails({ IdxList: _this.checkedMaterial.join(',') }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 合约物料的新增或编辑 ============================== begin====================================
    // 验证合约物料新增
    comfirmaddNewMaterial() {
      const _this = this
      if (!_this.contractInfo.Materials || _this.contractInfo.Materials.length < 1) {
        _this.$message('当前无任何新增明细可保存')
        return false
      }
      let newCount = 0
      _this.contractInfo.Materials.forEach(ele => {
        if (!ele.IDX || ele.IDX < 1) {
          newCount++
        }
      })
      if (newCount <= 0) {
        _this.$message('当前无任何新增明细可保存')
        return false
      }
      let typeMessage = '确认新增所有新增数据吗？' // 1保存全部  2 保存选中
      if (_this.checkedMaterial && _this.checkedMaterial.length > 0) {
        typeMessage = '确认新增选中的数据吗？'
      }
      _this.$confirm(typeMessage, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.addNewMaterial()
      }).catch(() => {
      })
    },
    // 新增物料
    addNewMaterial() {
      const _this = this
      _this.fullscreenLoading = true
      // 获取保存参数
      const _data = _this.dealMaterialSaveData(1)
      $contractreq.ContractDetailsSave({ detailsData: _data.join('|'), ContractIDX: _this.contractInfo.IDX }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('保存成功')
        } else {
          _this.$message('保存失败：' + res.RetMsg)
        }
      })
    },
    // 合约物料保存
    comfirmMaterialSave() {
      const _this = this
      if (!_this.contractInfo.Materials || _this.contractInfo.Materials.length < 1) {
        // _this.$message('当前无任何明细可保存')
        _this.IsEdit = false
        return false
      }
      let typeMessage = '确认保存所有数据吗？' // 1保存全部  2 保存选中
      if (_this.checkedMaterial && _this.checkedMaterial.length > 0) {
        typeMessage = '确认保存选中的数据吗？'
      }
      _this.$confirm(typeMessage, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.MaterialSave()
      }).catch(() => {
      })
    },
    // 处理保存数据 _type 1: 新增物料 2：编辑已保存物料
    dealMaterialSaveData(_type) {
      const _this = this
      const _data = []
      let _arry = _this.contractInfo.Materials
      if (_this.checkedMaterial && _this.checkedMaterial.length > 0) {
        // 处理选中数据
        _arry = _this.checkedMaterialitem
      }
      let _PriceFormat = null
      let _TaxRate = null
      let _CustomInvCode = null
      let _UserName = null
      _arry.forEach((ele) => {
        _PriceFormat = ele.PriceFormat
        if (!ele.PriceFormat) {
          _PriceFormat = ''
        }
        _TaxRate = ele.TaxRate
        if (!ele.TaxRate) {
          _TaxRate = ''
        }
        _CustomInvCode = ele.CustomInvCode
        if (!ele.CustomInvCode) {
          _CustomInvCode = ''
        }
        _UserName = ele.UserName
        if (!ele.UserName) {
          _UserName = ''
        }
        if (_type === 1) {
          // 只拼接新物料
          if (!ele.IDX || ele.IDX < 1) {
            _data.push(ele.IDX + '#' + ele.MaterialRefCompanyID + '#' + _PriceFormat + '#' + _TaxRate + '#' + _CustomInvCode + '#' + _UserName)
          }
        } else if (_type === 2) {
          if (ele.IDX && ele.IDX > 0) {
            _data.push(ele.IDX + '#' + ele.MaterialRefCompanyID + '#' + _PriceFormat + '#' + _TaxRate + '#' + _CustomInvCode + '#' + _UserName)
          }
        }
        // if (ele.IDX && ele.IDX > 0) {
        //   _data.push(ele.IDX + '#' + ele.MaterialRefCompanyID + '#' + ele.PriceFormat + '#' + ele.TaxRate + '#' + ele.CustomInvCode)
        // } else {
        //   _data.push('-1#' + ele.MaterialRefCompanyID + '#' + ele.PriceFormat + '#' + ele.TaxRate + '#' + ele.CustomInvCode)
        // }
      })
      return _data
    },
    MaterialSave() {
      const _this = this
      _this.fullscreenLoading = true
      // 获取保存参数
      const _data = _this.dealMaterialSaveData(2)
      $contractreq.ContractDetailsSave({ detailsData: _data.join('|'), ContractIDX: _this.contractInfo.IDX }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.IsEdit = false
          _this.$message('保存成功')
        } else {
          _this.$message('保存失败：' + res.RetMsg)
        }
      })
    },
    // 选中添加物料
    addmaterhandleSelectionChange(val) {
      this.checkedAddMaterial = val
    },
    // 添加选中物料
    addMaterial() {
      const _this = this
      // 处理选中数据，push到表格长度
      if (_this.checkedAddMaterial) {
        if (!_this.contractInfo.Materials) {
          _this.contractInfo.Materials = []
        }
        _this.checkedAddMaterial.forEach((ele) => {
          const _item = {
            IDX: -1,
            InvCode: ele.InvCode,
            MaterialRefCompanyID: ele.IDX,
            ManuPartNum: ele.ManuPartNum, // 原厂编号
            MaterialTypeName: ele.MaterialTypeName,
            MaterialSubTypeName: ele.MaterialSubTypeName,
            MaterialSpefication: ele.MRCSpecification, // 规格
            ImportUnit: ele.ImportUnit, // 单位
            MaterialName: ele.MRCInvName, // 物料名称
            MaterialBrandName: ele.BrandName, // 品牌
            PriceFormat: null, // 含税价格
            TaxRate: null, // 税率
            CustomInvCode: null, // 映射码
            UserName: _this.contractInfo.UserName, // 业务员
            SalesName: _this.contractInfo.SalesName, // 业务员
            Status: null,
            StatusString: '', // 物料状态
            IsEditDetails: false
          }
          _this.contractInfo.Materials.splice(0, 0, _item)
        })
      }
      _this.addMaterialVisbel = false
      this.$refs.materiallist.bodyWrapper.scrollTop = 0
    },
    // 处理添加选中物料
    addoneMaterial(row) {
      // 处理选中数据，push到表格长度
      if (!this.contractInfo.Materials) {
        this.contractInfo.Materials = []
      }
      const _item = {
        IDX: -1,
        MaterialRefCompanyID: row.IDX,
        InvCode: row.InvCode,
        ManuPartNum: row.ManuPartNum, // 原厂编号
        MaterialTypeName: row.MaterialTypeName,
        MaterialSubTypeName: row.MaterialSubTypeName,
        MaterialSpefication: row.MRCSpecification, // 规格
        ImportUnit: row.ImportUnit, // 单位
        MaterialName: row.MRCInvName, // 物料名称
        MaterialBrandName: row.BrandName, // 品牌
        PriceFormat: null, // 含税价格
        TaxRate: null, // 税率
        CustomInvCode: null, // 映射码
        UserName: this.contractInfo.UserName, // 业务员
        SalesName: this.contractInfo.SalesName, // 业务员
        Status: null,
        StatusString: '', // 物料状态
        IsEditDetails: false
      }
      this.contractInfo.Materials.splice(0, 0, _item)
      this.addMaterialVisbel = false
      this.$refs.materiallist.bodyWrapper.scrollTop = 0
    },
    // 打开物料选择框
    openAddMateiral() {
      // 获取物=物料列表
      this.listaddmaterialParas.keyWords = ''
      this.checkedAddMaterial = []
      this.getAddMaterialMaterlaiList()
    },
    // 合约物料的新增或编辑 ============================== end====================================
    // 物料明细上架下架
    enableContractDetails(_type) {
      const _this = this
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        _this.$message('请至少选择一条数据')
        return false
      }
      _this.fullscreenLoading = true
      const _DetailsData = []
      _this.checkedMaterial.forEach((ele) => {
        _DetailsData.push(ele + '#' + _type)
      })
      $contractreq.DetailsStatusUpdate({
        DetailsData: _DetailsData.join('|'),
        ContractIDX: _this.contractInfo.IDX
      }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('操作成功')
          _this.seletUserVisible = false
        } else {
          _this.$message('操作失败')
        }
      })
    },
    closedmaterialadvance() {
      this.listaddmaterialParas.IsAdvancedSearch = 0
    },
    // 打开物料高级搜索
    openamterialaddvance() {
      this.listaddmaterialParas = {
        IsAdvancedSearch: 1, // --是否高级搜索  0否 1是
        keyWords: '',
        SellCompanyID: null,
        InvCode: '', // 物料编号
        CertificateDeadLineBeginDate: '',
        CertificateDeadLineEndDate: '',
        BrandName: '', // 品牌
        InvName: '', // 物料名称
        MaterialTypeName: '', // 物料类型  --
        NickName: '', // 物料简称  --
        CertificateName: '', // 注册证号
        DINum: '', // udi码
        ManuPartNum: '', // 原厂编号
        ManfacturerName: '', // 生产厂家
        PageIndex: 1,
        PageSize: 10
      }
      this.AdvancedsearchmaterialVisibel = true
    },
    // 高级搜索
    confirmSercherMaterial() {
      this.listaddmaterialParas.PageIndex = 1
      this.getAddMaterialMaterlaiList()
      this.AdvancedsearchmaterialVisibel = false
    },
    // 获取添加物料时，物料的列表
    getAddMaterialMaterlaiList() {
      const _this = this
      _this.fullscreenLoading = true
      // 1-作为买方的合约(采购；0-作为卖方的合约（销售
      if (_this.contractInfo.ContractType === 0) {
        _this.listaddmaterialParas.SellCompanyID = _this.contractInfo.SellCompanyID
      } else if (_this.contractInfo.ContractType === 1) {
        _this.listaddmaterialParas.SellCompanyID = _this.contractInfo.BuyCompanyID
      } else {
        _this.listaddmaterialParas.SellCompanyID = 0
      }
      $contractreq.DetailsAddList(_this.listaddmaterialParas).then(res => {
        _this.fullscreenLoading = false
        _this.addMaterialVisbel = true
        if (res.RetCode === '0') {
          _this.addMaterialList = res.Message
          _this.dealaddMaterialList(_this)
          _this.addMateriallistSize = res.Total
        }
      })
    },
    // 处理添加的物料数据
    dealaddMaterialList(_this) {
      _this.addMaterialList.forEach((ele) => {
        ele.ischecked = false
        _this.contractInfo.Materials.forEach((row) => {
          if (ele.IDX === row.MaterialRefCompanyID) {
            ele.ischecked = true
            return false
          }
        })
      })
    },
    addMateriallistCurrentChange(val) {
      this.listaddmaterialParas.PageIndex = val
      this.getAddMaterialMaterlaiList()
    },
    // 获取人员信息
    getUserInfo() {
      const _this = this
      // _this.fullscreenLoading = true
      $userRequest.GetUserInfo().then(res => {
        // _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.userInfo = res.Message
          _this.isCanCopy = false
        }
      })
    },
    // 获取供应商数据
    getSellCompanyList() {
      const _this = this
      // _this.fullscreenLoading = true
      _this.sellCompanyListParas.CompanyType = 1
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        // _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.allSaleCompanyList = res.Message
        }
      })
    },
    // 获取买方数据
    // _type 1：all 0 只是终端
    getBuyCompanyList() {
      const _this = this
      // _this.fullscreenLoading = true
      _this.sellCompanyListParas.CompanyType = 0
      $contractreq.GetSellCompanyList(_this.sellCompanyListParas).then(res => {
        // _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.allbuyCompanyList = res.Message
          _this.allCustomerCompanyList = res.Message
        }
      })
    },
    // _type 1：列表选择业务员 2 创建合约选择业务员 3: 行项目选择业务员
    oprenSelectContractUser(_type, row = null) {
      const _this = this
      _this.selUserType = _type
      if (_type === 3 || _type === 4) {
        _this.contractDetailItemByOperate = row
      }
      if (_type === 1) {
        if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
          _this.$message('请至少选择一条物料明细！')
          return false
        }
      }
      _this.checkedUserInfo = null
      _this.getSelectUserQueryListOfDept()
      _this.seletUserVisible = true
    },
    // 获取选择人员时 ，成员列表
    getSelectUserQueryListOfDept() {
      const _this = this
      _this.fullscreenLoading = true
      _this.connuserlist = []
      $userRequest.SelectUserQueryListOfDept().then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.connuserlist = res.Message
        }
      })
    },
    // 选中人员
    checkeduser(ischecked, val, item) {
      const _this = this
      if (ischecked) {
        if (_this.checkedUserInfo) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        }
        if (item.children) {
          item.ischecked = !ischecked
          _this.$message('只能选择一个人员！')
          return false
        } else {
          _this.checkedUserInfo = item
          _this.checkedContractOptionUserID = item.IDX
          _this.checkedContractOptionUserName = item.UserName
          _this.checkedContractOptionUserNickName = item.GroupName
        }
      } else {
        _this.checkedUserInfo = null
        _this.checkedContractOptionUserID = null
        _this.checkedContractOptionUserName = null
        _this.checkedContractOptionUserNickName = null
      }
    },
    // 确认选中人员 _type 1：列表选择业务员 2 复制合约选择业务员 3 行项目修改业务员 4 新增物料选择业务员
    doconnuser() {
      const _this = this
      if (!_this.checkedUserInfo) {
        _this.$message('请选中人员！')
        return false
      }
      switch (_this.selUserType) {
        case 1:
          _this.dealContractListOperater()
          break
        case 2:
          _this.dealCreateContractOperater()
          break
        case 3:
          _this.dealCreateContractOperaterByidx()
          break
        case 4:
          _this.dealAddMateialContractOperater()
          break
        default: return
      }
      // const _this = this
    },
    // 过滤树形数据
    alluserfilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 合约明细修改业务员
    dealContractListOperater() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.EditContractDeatilSales({
        userName: _this.checkedUserInfo.UserName,
        ContractDeatilId: _this.checkedMaterial.join(','),
        salesName: _this.checkedUserInfo.GroupName,
        groupId: null,
        groupName: null
      }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('操作成功')
          _this.seletUserVisible = false
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 复制合约选择业务员
    dealCreateContractOperater() {
      const _this = this
      if (_this.checkedContractOptionUserName) {
        _this.contractInfoCopy.UserName = _this.checkedUserInfo.UserName
        _this.contractInfoCopy.NickName = _this.checkedUserInfo.GroupName
        _this.seletUserVisible = false
      } else {
        _this.$message('请选择人员再确定！')
      }
    },
    // 新增物料选择业务员
    dealAddMateialContractOperater() {
      const _this = this
      if (_this.checkedContractOptionUserName) {
        _this.contractDetailItemByOperate.UserName = _this.checkedUserInfo.UserName
        _this.contractDetailItemByOperate.SalesName = _this.checkedUserInfo.GroupName
        _this.seletUserVisible = false
      } else {
        _this.$message('请选择人员再确定！')
      }
    },
    // 行项目修改业务员
    dealCreateContractOperaterByidx() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.EditContractDeatilSales({
        userName: _this.checkedUserInfo.UserName,
        ContractDeatilId: _this.contractDetailItemByOperate.IDX,
        salesName: _this.checkedUserInfo.GroupName,
        groupId: null,
        groupName: null
      }).then(res => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('操作成功')
          _this.seletUserVisible = false
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 打开复制合约框
    opencopycontract() {
      const _this = this
      // 初始化新建数据 合约类型 -1-全部；1-作为买方的合约(采购；0-作为卖方的合约（销售
      if (_this.contractInfo.ContractType === 0) {
        _this.isEditSell = true
        _this.isEditBuy = false
        // 获取终端以及客户
        // _this.getBuyCompanyList(1)
        // 处理供应商数据
        _this.contractInfo.BuyCompanyID = null
        _this.contractInfo.SendCompanyID = null
        _this.SellcompanyList.push({ id: _this.userInfo.CompanyID, text: _this.userInfo.CompanyName })
        _this.contractInfo.SellCompanyID = _this.userInfo.CompanyID
      } else if (_this.contractInfo.ContractType === 1) {
        _this.isEditSell = false
        _this.isEditBuy = true
        // _this.getSellCompanyList()
        // _this.getBuyCompanyList(0)
        _this.contractInfo.SellCompanyID = null
        _this.contractInfo.SendCompanyID = null
        // 处理买方
        _this.BuyCompanyList.push({ id: _this.userInfo.CompanyID, text: _this.userInfo.CompanyName })
        _this.contractInfo.BuyCompanyID = _this.userInfo.CompanyID
      }
      _this.contractInfoCopy = _this.contractInfo
      _this.copycontractVisibel = true
    },
    // 确认复制合约
    confirmCopyContract() {
      const _this = this
      if (_this.contractInfo.ContractType === 0) {
        if (_this.contractInfoCopy.SellCompanyID === _this.contractInfoCopy.BuyCompanyID || _this.contractInfoCopy.SellCompanyID === _this.contractInfoCopy.CustomerCompanyID) {
          _this.$message('买方或者终端不能与供应商一致')
          return false
        }
      } else {
        if (_this.contractInfoCopy.BuyCompanyID === _this.contractInfoCopy.SellCompanyID) {
          _this.$message('买方不能与供应商一致')
          return false
        }
      }
      // 请求复制接口
      _this.fullscreenLoading = true
      _this.contractInfoCopy.contractCodeCopy = _this.contractInfo.IDX
      $contractreq.SetSalesContractSave(_this.contractInfoCopy).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$message('复制合约成功')
          this.createcontractVisibel = false
          _this.$router.push('/contract-salesContract/contract/contractDetails/' + res.Message)
        } else {
          _this.$message('复制合约失败：' + res.RetMsg)
        }
      })
    },
    // 处理上传附件 begin =================================
    // 打开上传框
    openUpload() {
      this.fileList = null
      this.fileBase64 = null
      this.filetype = null
      this.filename = null
      this.uploadVisibel = true
    },
    submitUpload() {
      // 处理参数
      const paras = {
        ContractID: this._contractID,
        Base64String: this.fileBase64,
        FileExtName: this.filetype,
        FileName: this.filename,
        Remark: ''
      }
      this.uploadFiles(paras)
    },
    getFile(file, fileList) {
      // 设置文件类型
      this.filetype = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
      this.filename = file.name
      this.getBase64(file.raw).then(res => {
        this.fileBase64 = res
      })
    },
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function() {
          imgResult = reader.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.onloadend = function() {
          resolve(imgResult)
        }
      })
    },
    // 上传附件
    uploadFiles(_paras) {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.UploadContractFile(_paras).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.$message('上传成功')
          _this.uploadVisibel = false
        } else {
          _this.$message('上传失败，请重试!')
          _this.fileList = null
        }
      })
    },
    // 处理上传附件 end  =================================
    // 处理导出合约明细
    dealexportData() {
      const _this = this
      // ContractID合约ID
      // ContractDetailsIDList
      // 合约明细ID 1,2,3,4
      // ContractType
      // 初始化
      // _this.exportParams = {
      //   ContractID: null,
      //   ContractDetailsIDList: null,
      //   ContractType: this._type
      // }
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        // 未选中
        _this.exportParams.ContractID = _this._contractID
      } else {
        _this.exportParams.ContractDetailsIDList = _this.checkedMaterial.join(',')
      }
      _this.exportData()
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    guid() {
      return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
    },
    // 处理字节流
    downloadFile(data, fileName) {
      var byteString = atob(data)
      var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
      var intArray = new Uint8Array(arrayBuffer) // 创建视图
      for (var i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i)
      }
      const blob = new Blob([intArray], { type: '' })
      // 获取heads中的filename文件名
      const downloadElement = document.createElement('a')
      // 创建下载的链接
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      // 下载后文件名
      downloadElement.download = fileName
      document.body.appendChild(downloadElement)
      // 点击下载
      downloadElement.click()
      // 下载完成移除元素
      document.body.removeChild(downloadElement)
      // 释放掉blob对象
      window.URL.revokeObjectURL(href)
    },
    // 导出合约明细
    exportData() {
      const _this = this
      _this.fullscreenLoading = true
      this.$refs.ExportLoading.open()
      this.$api.ExportContractDetailsAndCInfo(_this.exportParams).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        const name = this.$minCommon.getExportName('合约详情')
        this.$refs.ExportLoading.success(res.Message, name)
        return this.$minCommon.downloadFile(res.Message, name)
        // _this.downloadFile(res.Message, _this.guid())
      })
    },
    // 物料列表选中或者不选中
    checkedAddMaterialhandlechange(val) {
      this.checkedMaterial = []
      this.checkedMaterialitem = []
      if (val) {
        val.forEach((ele) => {
          this.checkedMaterial.push(ele.IDX)
          this.checkedMaterialitem.push(ele)
        })
      }
    },
    // 合约状态
    getInfo(index) {
      switch (index) {
        case 1:
          return '已启用'
        case 2:
          return '已禁用'
        case 3:
          return '待审核'
        case 4:
          return '待审核'
        default:
          return '待审核'
      }
    },
    // 根据id查询合约详情
    getContractDetailByid(_idx) {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.GetContactDetailsQueryList({ contactID: _this._contractID, PageIndex: _this.DetailsPageIndex, PageSize: _this.DetailsPageSize }).then((res) => {
        _this.fullscreenLoading = false
        _this.contractInfo = res.Message
        _this.DetailsTotalCount = res.Total
        if (res.RetCode === '0' && res.FieldStr) {
          if (JSON.parse(res.FieldStr).length > 0 && this.$refs.FieldManagement) {
            this.$nextTick(() => {
              this.contractDetails = JSON.parse(res.FieldStr)
            })
          }
        }
        // _this.detailsListParas.PageIndex = 1
        // _this.getContractDetailListByid()
        _this.enableShow(_this)
      })
    },
    // 根据id查询合约详情list分页
    GetContractDetailsByID() {
      const _this = this
      _this.MaterialListDataloading = true
      const paras = {
        ContactID: _this._contractID,
        PageIndex: _this.DetailsPageIndex,
        PageSize: _this.DetailsPageSize
      }
      $contractreq.GetContractDetailsByID(paras).then((res) => {
        _this.MaterialListDataloading = false
        _this.contractInfo.Materials = res.Message
        _this.enableDetails(_this)
        _this.DetailsTotalCount = res.Total
      })
    },
    enableDetails(_this) {
      if (_this.IsEdit) {
        _this.contractInfo.Materials.forEach((ele) => {
          ele.IsEditDetails = false
        })
      }
    },
    enableShow(_this) {
      switch (_this.contractInfo.Status) {
        case 1:
          _this.enable = true
          break
        case 2:
          _this.enable = false
          break
        default: _this.enable = null
      }
      // 处理明细编辑状态
      if (_this.IsEdit) {
        _this.contractInfo.Materials.forEach((ele) => {
          ele.IsEditDetails = !_this.IsEdit
        })
      }
      _this.fullscreenLoading = false
    },
    openUpdatePrice() {
      const _this = this
      if (!_this.checkedMaterial || _this.checkedMaterial.length < 1) {
        _this.$message('请至少选择一条明细改价')
        return false
      }
      _this.updatepriceVisibel = true
    },
    // 确认改价
    comfirmUpatePrice() {
      const _this = this
      if (_this.updatepriceInfo.type === 1 && !_this.updatepriceInfo.precent) {
        _this.$message('请填写比例')
        return false
      }
      if (_this.updatepriceInfo.type === 2 && !_this.updatepriceInfo.price) {
        _this.$message('请填写价格')
        return false
      }
      _this.$confirm('确认改价?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.UpatePrice()
      }).catch(() => {
      })
    },
    UpatePrice() {
      const _this = this
      _this.fullscreenLoading = true
      if (_this.updatepriceInfo.EditType === 1) {
        _this.updatepriceInfo.value = _this.updatepriceInfo.precent
      }
      if (_this.updatepriceInfo.EditType === 2) {
        _this.updatepriceInfo.value = _this.updatepriceInfo.price
      }
      _this.updatepriceInfo.ContactID = _this.contractInfo.IDX
      _this.updatepriceInfo.IdxList = _this.checkedMaterial.join(',')
      $contractreq.SaveContractPrice(_this.updatepriceInfo).then((res) => {
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          _this.getContractDetailByid(_this._contractID)
          _this.$message('操作成功')
          _this.updatepriceVisibel = false
        } else {
          _this.$message(res.Message)
        }
      })
    },
    // 验证选中科室
    confirmCheckedKS() {
      const _this = this
      let typeMessage = '确认选中科室 ？'
      if (!_this.checkedks || _this.checkedks.length < 1) {
        typeMessage = '确认不选中科室？'
      }
      _this.$confirm(typeMessage, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        _this.CheckedKS()
      }).catch(() => {
      })
    },
    // 选中科室
    CheckedKS() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.SaveContractRefGroup({
        GroupID: _this.checkedks.join(','),
        ContractID: _this.contractInfo.IDX
      }).then(res => {
        _this.fullscreenLoading = false
        this.departmentVisibel = false
        if (res.RetCode === '0') {
          _this.$message('操作成功')
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 选择科室时 value-- 右边的数据  direction 左边右边 movedKeys 选中的数据
    kshandleChange(value, direction, movedKeys) {
      this.checkedks = value
    },
    // 打开选择科室框
    openSelectKS() {
      this.getKSDataList()
    },
    // 获取科室数据
    getKSDataList() {
      const _this = this
      _this.fullscreenLoading = true
      $contractreq.GetGroupList({
        ContractID: _this.contractInfo.IDX
      }).then(res => {
        this.departmentVisibel = true
        _this.fullscreenLoading = false
        if (res.RetCode === '0') {
          // ksDataList checkedks
          _this.ksDataList = res.Message.groupList
          _this.checkedks = []
          if (res.Message.groupSelectedList && res.Message.groupSelectedList.length > 0) {
            _this.dealCheckedKs(_this, res.Message.groupSelectedList)
          }
        } else {
          _this.$message('操作失败')
        }
      })
    },
    // 获取科室数据后 渲染已选中的科室
    dealCheckedKs(_this, _data) {
      _data.forEach((ele) => {
        _this.checkedks.push(ele.key)
      })
    }
  }
}
export default contractDetails
